<!--
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-21 12:00:02
 * @LastEditors  : min
 * @LastEditTime : 2021-01-28 21:35:34
-->
<template>
  <div class="ml_login">
    <div class="ml_login_form">
      <div class="ml_login_form_content">
        <div class="ml_login_form_content_text">
          快卖车
        </div>
        <div class="ml_login_form_content_flex">
          <div class="ml_login_form_content_title">
            快卖车二手车交易平台
          </div>
          <div style="padding:0 10px;">
            <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules">
              <a-form-model-item prop="userName">
                <a-input v-model="ruleForm.userName" type="text" autocomplete="off" placeholder="账号" allowClear />
              </a-form-model-item>
              <a-form-model-item prop="pass">
                <a-input-password v-model="ruleForm.pass" type="password" autocomplete="off" placeholder="密码" allowClear />
              </a-form-model-item>
              <a-form-model-item>
                <a-row :gutter="10">
                  <a-col :span="12">
                    <a-button @click="resetForm('ruleForm')" block>
                      清空
                    </a-button>
                  </a-col>
                  <a-col :span="12">
                    <a-button type="primary" @click="submitForm('ruleForm')" block>
                      登录
                    </a-button>
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../utils/api"

export default {
  name: "Home",
  data() {
    let validateUser = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        userName: "",
        pass: ""
      },
      rules: {
        userName: [{ validator: validateUser, trigger: "change" }],
        pass: [{ validator: validatePass, trigger: "change" }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          login({
            name: that.ruleForm.userName,
            password: that.ruleForm.pass
          }).then(res => {
            sessionStorage.setItem("_wx_carwins_user_", JSON.stringify(res.data));
            that.$router.push("/home");
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="scss" scoped>
.ml_login {
  width: 100vw;
  height: 100vh;
  background-color: #40a9ff;
  position: relative;
  .ml_login_form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-20%, -50%);

    .ml_login_form_content {
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      padding: 0 10px 5px 10px;
      border-radius: 5px;
      margin: 0 auto;
      display: flex;

      .ml_login_form_content_text {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
      }

      .ml_login_form_content_flex {
        flex: 1;
        width: 280px;
        padding: 0 20px;
      }

      .ml_login_form_content_title {
        padding: 20px 0 40px 0;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
</style>
